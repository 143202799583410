import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import AppStoreIcon from "../images/app-store-badge.svg";

const IndexPage = () => {
  return (
    <Layout title="SquareMeter">
      <div className="max-w-screen-xl mx-auto p-5 md:p-24">
        <h2 className="text-3xl my-6 md:text-7xl md:my-0">
          EASIER HOUSE HUNTING!
        </h2>
        <h3 className="text-xl my-6 md:my-10">
          Scan the SquareMeter QR code at open homes, and immediately start
          exploring the open home.
        </h3>

        <div className="flex flex-row items-center flex-wrap">
          <a
            className="flex items-center px-4"
            style={{ height: 100, width: 260 }}
            href="#"
          >
            <StaticImage
              src="../images/google-play-badge.png"
              alt="Get it on Google Play"
              layout="fixed"
              placeholder="none"
              height={100}
            />
          </a>
          <a
            className="flex items-center px-4"
            style={{ height: 100, width: 260 }}
            href="#"
          >
            <AppStoreIcon
              style={{ height: "69px", width: "auto" }}
              alt="Download on the App Store"
            />
          </a>
        </div>
      </div>

      <div className="bg-gray-500 md:py-20 relative">
        <div className="max-w-screen-xl mx-auto">
          <StaticImage
            src="../images/clique-images-ImWVsIHiEWI-unsplash.jpg"
            alt="Scan and explore an open home"
            layout="fullWidth"
            placeholder="blurred"
            className="h-[300px] md:h-[500px]"
          />

          <div className="text-center absolute top-0 right-0 bottom-0 left-0 md:relative py-10 ">
            <p className="text-white py-2 text-lg">
              Track which open homes you visited, and view the property
              documents all in one convenient location&hellip;
            </p>
            <p className="text-white py-2 text-xl">On Your Phone!</p>
          </div>
        </div>
      </div>

      <div className="pb-10 md:py-20">
        <h2 className="text-3xl md:text-5xl text-center p-11 md:p-24">
          APP FEATURES
        </h2>

        <div className="max-w-screen-xl mx-auto grid grid-cols-1 grid-rows-3 md:grid-cols-3 md:grid-rows-1 auto-rows-fr gap-8 text-center">
          <div className="flex flex-col">
            <StaticImage
              src="../images/paul-hanaoka-HbyYFFokvm0-unsplash.jpg"
              alt="Property details available from your phone"
              layout="fullWidth"
              placeholder="blurred"
              className="h-72"
            />

            <div className="p-7 bg-gray-200 flex-grow">
              <h3 className="text-2xl my-10">PROPERTY DETAILS</h3>
              <p className="text-lg my-10">
                Spend less time extracting essential details from the real
                estate agent, and more time exploring the open home.
              </p>
            </div>
          </div>

          <div className="flex flex-col">
            <StaticImage
              src="../images/levi-jones-n0CTq0rroso-unsplash.jpg"
              alt="Scan and visit, not need to stand in queues to register your visit"
              layout="fullWidth"
              placeholder="blurred"
              className="h-72"
            />

            <div className="p-7 bg-gray-200 flex-grow">
              <h3 className="text-2xl my-10">SKIP THE QUEUE</h3>
              <p className="text-lg my-10">
                Avoid queuing to provide your personal details to the agent.
                Scanning the open home QR code is all you need to do.
              </p>
            </div>
          </div>

          <div className="flex flex-col">
            <StaticImage
              src="../images/michael-amadeus-oidJ1WGkIeY-unsplash.jpg"
              alt="Stay safe, don't touch those shared pens. Scan the SquareMeter QR code and be safe"
              layout="fullWidth"
              placeholder="blurred"
              className="h-72"
            />

            <div className="p-7 bg-gray-200 flex-grow">
              <h3 className="text-2xl my-10">NO SHARING PENS</h3>
              <p className="text-lg my-10">
                Some real estate agents still rely on pen &amp; paper to record
                visitor details. Demand to scan the SquareMeter QR code instead!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-yellow-300 md:py-20">
        <h2 className="text-3xl md:text-5xl text-center pt-11 md:p-24">
          FREQUENTLY ASKED QUESTIONS
        </h2>

        <div className="max-w-screen-xl pb-5 md:pb-0 mx-auto md:grid md:grid-cols-2 md:grid-rows-2 md:auto-rows-fr md:gap-4">
          <div className="m-10">
            <h3 className="text-2xl md:mb-10">
              WHAT IF I'VE RUN OUT OF MOBILE DATA?
            </h3>
            <p className="text-lg my-5">
              Scanning an open home QR code requires mobile connectivity. But
              we've got you covered.
            </p>
            <p className="text-lg my-5">
              Open 'My QR Code' in the app and the agent can scan your QR code.
              We track the open home visit for you and you can explore the
              property in the app when you've got mobile connectivity again.
            </p>
          </div>

          <div className="m-10">
            <h3 className="text-2xl md:mb-10">
              HOW OFTEN ARE THERE UPDATES TO THE APP?
            </h3>
            <p className="text-lg my-5">
              We're constantly &amp; actively developing the mobile app, and
              looking for feedback to make it better.
            </p>
            <p className="text-lg my-5">
              If you want to share your feedback please use the Feedback form on
              the Contact Us page.
            </p>
          </div>

          <div className="m-10">
            <h3 className="text-2xl md:mb-10">
              SOUNDS GREAT&hellip; WHAT'S THE PRICE?
            </h3>
            <p className="text-lg my-5">
              It's a <strong>free</strong> service for those house hunting!
            </p>
            <p className="text-lg my-5">
              We won't ever start charging you and won't ask you to provide any
              payment details.
            </p>
          </div>

          <div className="m-10">
            <h3 className="text-2xl md:mb-10">
              WHAT MOBILE PHONES ARE SUPPORTED?
            </h3>
            <p className="text-lg my-5">
              Most latest iPhone and Android mobiles are supported. If you're
              unsure if we support your device visit the Apple App Store or
              Android Play Store and see if it's available for install.
            </p>
            <p className="text-lg my-5">
              If you cannot install our app ask the real estate agent to
              register your open home visit in their mobile; we provide this
              feature as well!
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
